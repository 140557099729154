/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'journal-richtext': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7.5 3.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0m-.861 1.542l1.33.886 1.854-1.855a.25.25 0 01.289-.047L11 4.75V7a.5.5 0 01-.5.5h-5A.5.5 0 015 7v-.5s1.54-1.274 1.639-1.208M5 9.5a.5.5 0 01.5-.5h5a.5.5 0 010 1h-5a.5.5 0 01-.5-.5m0 2a.5.5 0 01.5-.5h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5"/><path pid="1" d="M3 0h10a2 2 0 012 2v12a2 2 0 01-2 2H3a2 2 0 01-2-2v-1h1v1a1 1 0 001 1h10a1 1 0 001-1V2a1 1 0 00-1-1H3a1 1 0 00-1 1v1H1V2a2 2 0 012-2"/><path pid="2" d="M1 5v-.5a.5.5 0 011 0V5h.5a.5.5 0 010 1h-2a.5.5 0 010-1zm0 3v-.5a.5.5 0 011 0V8h.5a.5.5 0 010 1h-2a.5.5 0 010-1zm0 3v-.5a.5.5 0 011 0v.5h.5a.5.5 0 010 1h-2a.5.5 0 010-1z"/>',
    },
});
